import React, { useEffect, useState } from "react";
import "./index.scss";
import { Link, useLocation } from "react-router-dom";
import { useDispatch} from "react-redux";
import { getNewsById } from "../../store/Slices/newsSlice";
import SmallNewsCard from "../SmallNewsCard";
import { FaArrowRight, FaRegCalendarAlt } from "react-icons/fa";
import { AddsArr, NewsArr } from "../../constants";

const NewsPage = () => {
  const [currentNews, setCurrentNews] = useState(null);
  const [newsId, setNewsId] = useState(0);
  const copyNewsList = [...NewsArr];
  const dispatch = useDispatch();
  const currentURL = useLocation();

  const getPageId = () => {
    const path = currentURL.pathname.split("/");
    const page = path[1];
    const id = parseInt(path[2], 10);
    setNewsId(id);
    return page;
  };

  const fetchNewsById = (page, id) => {
    const dataArr = page === "news" ? NewsArr : AddsArr;
    const result = dataArr.find((item) => item.id === id);
    return result;
  };

  useEffect(() => {
    const page = getPageId();
    dispatch(getNewsById({ id: newsId }));
    const news = fetchNewsById(page, newsId);
    setCurrentNews(news);
  }, [currentURL.pathname, dispatch, newsId]);

  return !currentNews ? (
    "Loading"
  ) : (
    <div className="news-page">
      <div className="container">
        <div className="news-page__content">
          <div className="news-page__img-block">
            <img src={currentNews.img} alt={currentNews.title} />
          </div>
          <p className="news-page__date">
            <FaRegCalendarAlt />
            {currentNews.date}
          </p>
          <h1 className="news-page__title title">{currentNews.title}</h1>
          <div>
            {currentNews.text.split("\n").map((text, indx) => (
              <p className="news-page__text" key={indx}>
                {text}
              </p>
            ))}
          </div>
        </div>
        <div className="news-page__latest__news">
          <h2 className="news-page__latest-news-title title">
            Последние новости
          </h2>
          <div className="news-page__cards">
            {copyNewsList.slice(-8).map((news, indx) => (
              <SmallNewsCard key={indx} news={news} />
            ))}
          </div>
          <Link className="news-page__link" to="/news">
            Все новости <FaArrowRight />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default NewsPage;
