import news1 from "../assets/news/news-1.jpg";
import news2 from "../assets/news/news-2.jpg";
import news3 from "../assets/news/news-3.jpg";
import news4 from "../assets/news/news-4.jpg";
import news5 from "../assets/news/news-5.jpg";
import news6 from "../assets/news/news-6.jpg";
import news7 from "../assets/news/news-7.jpg";
import news8 from "../assets/news/news-8.jpg";
import add1 from "../assets/news/add1.jpg";
import add2 from "../assets/news/add2.jpg";
import add3 from "../assets/news/add3.jpg";
import add4 from "../assets/news/add4.jpg";
import add5 from "../assets/news/add5.jpg";
import add6 from "../assets/news/add6.jpg";
import add7 from "../assets/news/add7.jpg";
import add8 from "../assets/news/add8.jpg";
import add9 from "../assets/news/add9.jpg";
import add10 from "../assets/news/add10.jpg";
import add11 from "../assets/news/add11.jpg";

// export const APILINK = "http://localhost:3000";
export const APILINK = "https://keis-back-45ea8fbe543e.herokuapp.com";

// -----------------------------------------------

export const EducationalPlans = [
  {
    id: 1,
    year: "2018-2019",
    plans: [
      {
        id: 1,
        name: "По специальности «Гостиничный сервис»",
        link: "#",
      },
      {
        id: 2,
        name: "По специальности «Прикладная информатика»",
        link: "#",
      },
      {
        id: 3,
        name: `По специальности «Финансы (по отраслям)»`,
        link: "#",
      },
      {
        id: 4,
        name: "По специальности «Налоги и налогообложение»",
        link: "#",
      },
      {
        id: 5,
        name: "По специальности «Банковское дело»",
        link: "#",
      },
      {
        id: 6,
        name: "По специальности «Товароведение и экспертиза качества потребительских товаров»",
        link: "#",
      },
      {
        id: 7,
        name: "По специальности «Правоведение»",
        link: "https://drive.google.com/file/d/1wumKniLmkpjdgWzRHYespq0zmkpSl4b_/view",
      },
      {
        id: 8,
        name: 'По специальности "Туризм"',
        link: "https://drive.google.com/file/d/1jEnpcsk-LTefyDDHvJULRXrCB5DTxRWD/view",
      },
      {
        id: 10,
        name: "По специальности «Страховое право»",
        link: "https://drive.google.com/file/d/1kPpG2VwBuhwZ4b_AmdjtYVITrV8_47Uj/view",
      },
      {
        id: 11,
        name: "По специальности «Организация обслуживания в общественном питании»",
        link: "https://drive.google.com/file/d/1qt6IJla_7yuOiRaYWQBfhYFBXoFPxNEn/view",
      },
    ],
  },
  {
    id: 2,
    year: "2022-2023",
    plans: [
      {
        id: 1,
        name: "По специальности «Правоведение»",
        link: "https://drive.google.com/file/d/1stXMPanIBSAXkvMQ3q57c6wXtkwoZM8m/view",
      },
      {
        id: 2,
        name: "По специальности «Финансы»",
        link: "https://drive.google.com/file/d/1aetJdWUotHJTC43-n0JQBlAratQxoTV5/view",
      },
      {
        id: 3,
        name: "По специальности «Налоги и налогообложение»",
        link: "https://drive.google.com/file/d/1DHfUALMn9N4Y6_CzW4x_46EXBtHLvfgH/view",
      },
      {
        id: 4,
        name: "По специальности «Экономика и бух.учет»",
        link: "https://drive.google.com/file/d/1jcmo1cpnOhaStSk6bTsRUt0x0PlHJDyu/view",
      },
      {
        id: 5,
        name: "По специальности «Банковское дело»",
        link: "https://drive.google.com/file/d/1iP-Wx4DdVNAgBWoqEGRD0U8bD9JuUtYK/view",
      },
      {
        id: 6,
        name: "По специальности «Организация обслуживания в гостиницах и туристских комплексах»",
        link: "https://drive.google.com/file/d/1FjgsUR8A0e4r1Eup1zG5yxHOAsH7uA7x/view",
      },
      {
        id: 7,
        name: "По специальности «Туризм»",
        link: "https://drive.google.com/file/d/1LJlD577KXuFIXU8FCUsNWycrf0eRWv7P/view",
      },
      {
        id: 8,
        name: "По специальности «Прикладная информатика»",
        link: "https://drive.google.com/file/d/1xC-Ao23jNwLRyOpI2KoyibUs3q6WfPBD/view",
      },
      {
        id: 9,
        name: "По специальности «Технология продукции общественного питания»",
        link: "https://drive.google.com/file/d/1HfZeheOA2fKUzhVDwAeGQPQ8vDSoCyFh/view",
      },
      {
        id: 10,
        name: `По специальности «Менеджмент (по отраслям)»`,
        link: "https://drive.google.com/file/d/1DxRNv44y2hyZNgN8UTtrLYVCp6y-vGrm/view",
      },
      {
        id: 11,
        name: "По специальности «Право и организация социального обеспечения»",
        link: "https://drive.google.com/file/d/1i4CYQvxYPe0YgTpnE2G_PlRj2c4t48cA/view",
      },
      {
        id: 12,
        name: "По специальности «Социальная педагогика»",
        link: "https://drive.google.com/file/d/1sS2ZXO-w8_KJ6I-pVeLNMLZ3_dRbX-mK/view",
      },
    ],
  },
];

// -----------------------------------------------

export const HeaderNavigatinLinks = [
  {
    id: 1,
    name: "Главная",
    to: "/",
  },
  {
    id: 2,
    name: "О нас",
    to: "/about",
  },
  {
    id: 3,
    name: "Структура",
    to: "/structure",
  },
  {
    id: 4,
    name: "Специальность",
    to: "/directions",
  },
  {
    id: 5,
    name: "Абитуриент",
    to: "/applicant",
  },
  {
    id: 6,
    name: "Учебный процесс",
    to: "/educational-process",
  },
  {
    id: 7,
    name: "Документация",
    to: "/docs",
  },
];

export const Courses = [
  {
    id: 1,
    title: "Правоведение",
    code: "030503",
    qualification: "Юрист",
    secondary: {
      year: 1,
      month: 10,
    },
    basic: {
      year: 2,
      month: 10,
    },
    form: ["Очное", "Заочное"],
  },
  {
    id: 1,
    title: "Организация обслуживания в гостиницах и туристических комплексах",
    code: "030503",
    qualification: "Юрист",
    secondary: {
      year: 1,
      month: 10,
    },
    basic: {
      year: 2,
      month: 10,
    },
    form: ["Очное", "Заочное"],
  },
  {
    id: 1,
    title: "Правоведение",
    code: "030503",
    qualification: "Юрист",
    secondary: {
      year: 1,
      month: 10,
    },
    basic: {
      year: 2,
      month: 10,
    },
    form: ["Очное", "Заочное"],
  },
  {
    id: 1,
    title: "Правоведение",
    code: "030503",
    qualification: "Юрист",
    secondary: {
      year: 1,
      month: 10,
    },
    basic: {
      year: 2,
      month: 10,
    },
    form: ["Очное", "Заочное"],
  },
  {
    id: 1,
    title: "Правоведение",
    code: "030503",
    qualification: "Юрист",
    secondary: {
      year: 1,
      month: 10,
    },
    basic: {
      year: 2,
      month: 10,
    },
    form: ["Очное", "Заочное"],
  },
  {
    id: 1,
    title: "Правоведение",
    code: "030503",
    qualification: "Юрист",
    secondary: {
      year: 1,
      month: 10,
    },
    basic: {
      year: 2,
      month: 10,
    },
    form: ["Очное", "Заочное"],
  },
  {
    id: 1,
    title: "Правоведение",
    code: "030503",
    qualification: "Юрист",
    secondary: {
      year: 1,
      month: 10,
    },
    basic: {
      year: 2,
      month: 10,
    },
    form: ["Очное", "Заочное"],
  },
  {
    id: 1,
    title: "Правоведение",
    code: "030503",
    qualification: "Юрист",
    secondary: {
      year: 1,
      month: 10,
    },
    basic: {
      year: 2,
      month: 10,
    },
    form: ["Очное", "Заочное"],
  },
  {
    id: 1,
    title: "Правоведение",
    code: "030503",
    qualification: "Юрист",
    secondary: {
      year: 1,
      month: 10,
    },
    basic: {
      year: 2,
      month: 10,
    },
    form: ["Очное", "Заочное"],
  },
];

export const AllCourses = [
  {
    direction: "030503",
    specialty: "Правоведение",
    qualification: "Юрист",
    durationFromBasicEducation: "1 год 10 месяцев",
    durationFromSecondaryEducation: "2 года 10 месяцев",
    formOfEducation: "Очное, заочное",
    typesOfProfessionalActivities: [
      "Правоприменительная",
      "правоохранительная",
      "консультационная",
      "педагогическая",
    ],
    qualification: "Юрист",
  },
  {
    direction: "080106",
    specialty: "Финансы (по отраслям)",
    qualification: "Финансист",
    durationFromBasicEducation: "1 год 10 месяцев",
    durationFromSecondaryEducation: "2 года 10 месяцев",
    formOfEducation: "Очное, заочное",
    typesOfProfessionalActivities: [
      "Ведение финансовый организации",
      "проведение расчетов с бюджетными и внебюджетными организациями",
      "проведение анализа финансово – хозяйственной деятельности организации",
      "управление оборотными активами, денежным потоком и капиталом",
      "начисление и выплата заработной планы и ведение налогового учёта",
    ],
    qualification: "Финансист",
  },
  {
    direction: "080107",
    specialty: "Налоги и налогообложение",
    qualification: "Специалист по налогообложению",
    durationFromBasicEducation: "1 год 10 месяцев",
    durationFromSecondaryEducation: "2 года 10 месяцев",
    formOfEducation: "Очное",
    typesOfProfessionalActivities: [
      "Взимание налогов",
      "контроля и проверка деятельности налогоплательщика",
      "учёт налоговый поступления, налоговый санкция и штрафов",
    ],
    qualification: "Специалист по налогообложению",
  },
  {
    direction: "080110",
    specialty: "Экономика и бухгалтерский учет (по отраслям)",
    qualification: "Бухгалтер",
    durationFromBasicEducation: "1 год 10 месяцев",
    durationFromSecondaryEducation: "2 года 10 месяцев",
    formOfEducation: "Очное, заочное",
    typesOfProfessionalActivities: [
      "Учетно-аналитическая",
      "экономическая",
      "финансово-контрольная",
    ],
    qualification: "Бухгалтер",
  },
  {
    direction: "080108",
    specialty: "Банковское дело",
    qualification: "Специалист банковского дела",
    durationFromBasicEducation: "1 год 10 месяцев",
    durationFromSecondaryEducation: "2 года 10 месяцев",
    formOfEducation: "Очное, заочное",
    typesOfProfessionalActivities: [
      "Ведение и учёт операция с наличными денежными средствами в национальный и иностранный валюта",
      "ведение учёта внутрибанковских операция",
      "ведение расчётных и сберегательных операций, операция с иностранный валютой",
      "анализ и учёт кредитные операция, выполнения операция с ценными бумагами",
    ],
    qualification: "Специалист по банковскому делу",
  },
  {
    direction: "100105",
    specialty:
      "Организация обслуживания в гостиницах и туристических комплексах",
    qualification: "Менеджер",
    durationFromBasicEducation: "1 год 10 месяцев",
    durationFromSecondaryEducation: "2 года 10 месяцев",
    formOfEducation: "Очное",
    typesOfProfessionalActivities: [
      "Бронирование гостиничных услуг",
      "прием, размещение и выписка гостей",
      "организация обслуживания гостей в процессе проживания",
      "продажи гостиничного продукта",
    ],
    qualification: "Менеджер",
  },
  {
    direction: "100201",
    specialty: "Туризм",
    qualification: "Специалист по туризму",
    durationFromBasicEducation: "1 год 10 месяцев",
    durationFromSecondaryEducation: "2 года 10 месяцев",
    formOfEducation: "Очное",
    typesOfProfessionalActivities: [
      "Предоставление турагентских услуг",
      "предоставление услуг по сопровождению туристов",
      "предоставление туроператорских услуг",
    ],
    qualification: "Специалист по туризму",
  },
  {
    direction: "230701",
    specialty: "Прикладная информатика (по отраслям)",
    qualification: "Техник",
    durationFromBasicEducation: "1 год 10 месяцев",
    durationFromSecondaryEducation: "2 года 10 месяцев",
    formOfEducation: "Очное",
    typesOfProfessionalActivities: [
      "Обработка информации",
      "наладка и обслуживание оборудования отраслевой направленности в производственных, обслуживающих, торговых организациях",
    ],
    qualification: "Техник-программист",
  },
  {
    direction: "260502",
    specialty: "Технология продукции общественного питания",
    qualification: "Техник-технолог",
    durationFromBasicEducation: "2 года 6 месяцев",
    durationFromSecondaryEducation: "3 года 6 месяцев",
    formOfEducation: "Очное",
    typesOfProfessionalActivities: [
      "Производственно-технологическая",
      "организационно-управленческая",
      "контрольно-технологическая",
    ],
    qualification: "Техник-технолог",
  },
  {
    direction: "080501",
    specialty: "Менеджмент (по отраслям)",
    qualification: "менеджер",
    durationFromBasicEducation: "1 год 10 месяцев",
    durationFromSecondaryEducation: "2 года 10 месяцев",
    formOfEducation: "Очное, заочное",
    typesOfProfessionalActivities: [
      "Организационно – управленческая",
      "планово- финансовая",
      "проектно-исследовательская",
    ],
    qualification: "Менеджер",
  },
  {
    direction: "030504",
    specialty: "Право и организация социального обеспечения",
    qualification: "юрист",
    durationFromBasicEducation: "1 год 10 месяцев",
    durationFromSecondaryEducation: "2 года 10 месяцев",
    formOfEducation: "Очное, заочное",
    typesOfProfessionalActivities: [
      "Обеспечение реализации прав граждан в сфере пенсионного обеспечения и социальной защиты",
      "организационное обеспечение деятельности учреждений социальной защиты населения",
    ],
    qualification: "Юрист",
  },
  {
    direction: "050711",
    specialty: "Социальная педагогика",
    qualification: "социальный педагог",
    durationFromBasicEducation: "1 год 10 месяцев",
    durationFromSecondaryEducation: "2 года 10 месяцев",
    formOfEducation: "Очное",
    typesOfProfessionalActivities: [
      "Социально-педагогическая",
      "диагностическая",
      "коррекционно-развивающая и реабилитационная",
    ],
    qualification: "Социальный педагог",
  },
];

// export const NewsArray = [
//   {
//     id: 1,
//     img: "https://www.keu.kg/uploads/news/TfNvDeKzYwA2Y_p_z_1y0lozY1qGyeAt.jpg",
//     title: "НИУ КЭУ -  Образование без границ!",
//     subtitle: "Профориентационная работа от кафедры ФСиГН",
//     date: "01.03.2024",
//     text: [
//       `28 февраля 2024 года сотрудники кафедры «Философии и социально-гуманитарных наук» к.соц.н., доцент Мамырканов М.А., методист Абас уулу Б., совместно со студентами 2 - курса группы ОСР-1-22 Дуйшенбиевой М., Бейшеналы кызы М. провели профориентационную работу в средней общеобразовательной школе № 41 города Бишкек.

//       Профориентационная работа была проведена для школьников 9 «А», 9 «Б» и 11 «А» классов.

//       Доцент Мамырканов М. А. выступая перед школьниками отметил, что НИУ КЭУ им. М. Рыскулбекова является одним из ведущих вузов по подготовке специалистов во всех сферах Кыргызской Республики, экономики, социальной работы, социальной психологии, туризма, IT-технологий, управления бизнесом, менеджмента. Занятия проводятся на государственном, русском, английском языках. Университет имеет современную инфраструктуру и имеет удобное месторасположение в центре города.

//       В рамках профориентационной работы Абас уулу Бекжан остановился, что НИУ КЭУ им. М. Рыскулбекова проводит активную работу в сфере международного сотрудничества, предоставляя студентам уникальные возможности академической мобильности, имея партнерские отношения с вузами ближнего и дальнего зарубежья. Это открывает перед студентами перспективы для обучения за рубежом, расширяя горизонты и предоставляя возможность получить образование на мировом уровне. В университете учебный процесс отвечает современным образовательным стандартам, используются инновационные методики, квалифицированный преподавательский состав, что создает благоприятную образовательную среду для студентов и способствует успешной учебе.

//       Дуйшенбиева Медина рассказала, что НИУ КЭУ им. М. Рыскулбекова предоставляет уникальную возможность студентам получить высшее образование по доступной цене, соответствующее требованиям мировых стандартов в области образования, бакалавриата, магистратуры, что позволяет проходить стажировки и практику в странах ближнего и дальнего зарубежья. Медина отметила, также об обучении с применением дистанционных технологий.

//       Бейшеналы к Мирана отметила, что, обучаясь в университете, студенты могут получить не только актуальные знания, но и уникальные навыки, необходимые для успешной адаптации в меняющемся мире. В вузе студенты активно принимают участие в молодежных организациях. Университет формирует лидеров, готовых встречать вызовы будущего с уверенностью и получить реальное будущее.

//       Встреча со школьниками прошла с восторгом и впечатлениями. Участники мероприятия были заинтересованы обучением в НИУ КЭУ им. М. Рыскулбекова, были собраны контакты для дальнейшего глубокого ознакомления с университетом в День открытых дверей.`,
//     ],
//     departament: "Кафедра «Философии и социально-гуманитарных наук»",
//   },
//   {
//     id: 2,
//     img: "https://www.keu.kg/uploads/news/hBGYuKq8QiJCK-cgScLQ7-3dgPX2YiyA.jpeg",
//     title: "НИУ КЭУ -  Образование без границ!",
//     subtitle: "Профориентационная работа от кафедры ФСиГН",
//     date: "01.03.2024",
//     text: [
//       `28 февраля 2024 года сотрудники кафедры «Философии и социально-гуманитарных наук» к.соц.н., доцент Мамырканов М.А., методист Абас уулу Б., совместно со студентами 2 - курса группы ОСР-1-22 Дуйшенбиевой М., Бейшеналы кызы М. провели профориентационную работу в средней общеобразовательной школе № 41 города Бишкек.

//       Профориентационная работа была проведена для школьников 9 «А», 9 «Б» и 11 «А» классов.

//       Доцент Мамырканов М. А. выступая перед школьниками отметил, что НИУ КЭУ им. М. Рыскулбекова является одним из ведущих вузов по подготовке специалистов во всех сферах Кыргызской Республики, экономики, социальной работы, социальной психологии, туризма, IT-технологий, управления бизнесом, менеджмента. Занятия проводятся на государственном, русском, английском языках. Университет имеет современную инфраструктуру и имеет удобное месторасположение в центре города.

//       В рамках профориентационной работы Абас уулу Бекжан остановился, что НИУ КЭУ им. М. Рыскулбекова проводит активную работу в сфере международного сотрудничества, предоставляя студентам уникальные возможности академической мобильности, имея партнерские отношения с вузами ближнего и дальнего зарубежья. Это открывает перед студентами перспективы для обучения за рубежом, расширяя горизонты и предоставляя возможность получить образование на мировом уровне. В университете учебный процесс отвечает современным образовательным стандартам, используются инновационные методики, квалифицированный преподавательский состав, что создает благоприятную образовательную среду для студентов и способствует успешной учебе.

//       Дуйшенбиева Медина рассказала, что НИУ КЭУ им. М. Рыскулбекова предоставляет уникальную возможность студентам получить высшее образование по доступной цене, соответствующее требованиям мировых стандартов в области образования, бакалавриата, магистратуры, что позволяет проходить стажировки и практику в странах ближнего и дальнего зарубежья. Медина отметила, также об обучении с применением дистанционных технологий.

//       Бейшеналы к Мирана отметила, что, обучаясь в университете, студенты могут получить не только актуальные знания, но и уникальные навыки, необходимые для успешной адаптации в меняющемся мире. В вузе студенты активно принимают участие в молодежных организациях. Университет формирует лидеров, готовых встречать вызовы будущего с уверенностью и получить реальное будущее.

//       Встреча со школьниками прошла с восторгом и впечатлениями. Участники мероприятия были заинтересованы обучением в НИУ КЭУ им. М. Рыскулбекова, были собраны контакты для дальнейшего глубокого ознакомления с университетом в День открытых дверей.`,
//     ],
//     departament: "Кафедра «Философии и социально-гуманитарных наук»",
//   },
//   {
//     id: 3,
//     img: "https://www.keu.kg/uploads/news/TfNvDeKzYwA2Y_p_z_1y0lozY1qGyeAt.jpg",
//     title: "НИУ КЭУ -  Образование без границ!",
//     subtitle: "Профориентационная работа от кафедры ФСиГН",
//     date: "01.03.2024",
//     text: [
//       `28 февраля 2024 года сотрудники кафедры «Философии и социально-гуманитарных наук» к.соц.н., доцент Мамырканов М.А., методист Абас уулу Б., совместно со студентами 2 - курса группы ОСР-1-22 Дуйшенбиевой М., Бейшеналы кызы М. провели профориентационную работу в средней общеобразовательной школе № 41 города Бишкек.

//       Профориентационная работа была проведена для школьников 9 «А», 9 «Б» и 11 «А» классов.

//       Доцент Мамырканов М. А. выступая перед школьниками отметил, что НИУ КЭУ им. М. Рыскулбекова является одним из ведущих вузов по подготовке специалистов во всех сферах Кыргызской Республики, экономики, социальной работы, социальной психологии, туризма, IT-технологий, управления бизнесом, менеджмента. Занятия проводятся на государственном, русском, английском языках. Университет имеет современную инфраструктуру и имеет удобное месторасположение в центре города.

//       В рамках профориентационной работы Абас уулу Бекжан остановился, что НИУ КЭУ им. М. Рыскулбекова проводит активную работу в сфере международного сотрудничества, предоставляя студентам уникальные возможности академической мобильности, имея партнерские отношения с вузами ближнего и дальнего зарубежья. Это открывает перед студентами перспективы для обучения за рубежом, расширяя горизонты и предоставляя возможность получить образование на мировом уровне. В университете учебный процесс отвечает современным образовательным стандартам, используются инновационные методики, квалифицированный преподавательский состав, что создает благоприятную образовательную среду для студентов и способствует успешной учебе.

//       Дуйшенбиева Медина рассказала, что НИУ КЭУ им. М. Рыскулбекова предоставляет уникальную возможность студентам получить высшее образование по доступной цене, соответствующее требованиям мировых стандартов в области образования, бакалавриата, магистратуры, что позволяет проходить стажировки и практику в странах ближнего и дальнего зарубежья. Медина отметила, также об обучении с применением дистанционных технологий.

//       Бейшеналы к Мирана отметила, что, обучаясь в университете, студенты могут получить не только актуальные знания, но и уникальные навыки, необходимые для успешной адаптации в меняющемся мире. В вузе студенты активно принимают участие в молодежных организациях. Университет формирует лидеров, готовых встречать вызовы будущего с уверенностью и получить реальное будущее.

//       Встреча со школьниками прошла с восторгом и впечатлениями. Участники мероприятия были заинтересованы обучением в НИУ КЭУ им. М. Рыскулбекова, были собраны контакты для дальнейшего глубокого ознакомления с университетом в День открытых дверей.`,
//     ],
//     departament: "Кафедра «Философии и социально-гуманитарных наук»",
//   },
//   {
//     id: 4,
//     img: "https://www.keu.kg/uploads/news/hBGYuKq8QiJCK-cgScLQ7-3dgPX2YiyA.jpeg",
//     title: "НИУ КЭУ -  Образование без границ!",
//     subtitle: "Профориентационная работа от кафедры ФСиГН",
//     date: "01.03.2024",
//     text: [
//       `28 февраля 2024 года сотрудники кафедры «Философии и социально-гуманитарных наук» к.соц.н., доцент Мамырканов М.А., методист Абас уулу Б., совместно со студентами 2 - курса группы ОСР-1-22 Дуйшенбиевой М., Бейшеналы кызы М. провели профориентационную работу в средней общеобразовательной школе № 41 города Бишкек.

//       Профориентационная работа была проведена для школьников 9 «А», 9 «Б» и 11 «А» классов.

//       Доцент Мамырканов М. А. выступая перед школьниками отметил, что НИУ КЭУ им. М. Рыскулбекова является одним из ведущих вузов по подготовке специалистов во всех сферах Кыргызской Республики, экономики, социальной работы, социальной психологии, туризма, IT-технологий, управления бизнесом, менеджмента. Занятия проводятся на государственном, русском, английском языках. Университет имеет современную инфраструктуру и имеет удобное месторасположение в центре города.

//       В рамках профориентационной работы Абас уулу Бекжан остановился, что НИУ КЭУ им. М. Рыскулбекова проводит активную работу в сфере международного сотрудничества, предоставляя студентам уникальные возможности академической мобильности, имея партнерские отношения с вузами ближнего и дальнего зарубежья. Это открывает перед студентами перспективы для обучения за рубежом, расширяя горизонты и предоставляя возможность получить образование на мировом уровне. В университете учебный процесс отвечает современным образовательным стандартам, используются инновационные методики, квалифицированный преподавательский состав, что создает благоприятную образовательную среду для студентов и способствует успешной учебе.

//       Дуйшенбиева Медина рассказала, что НИУ КЭУ им. М. Рыскулбекова предоставляет уникальную возможность студентам получить высшее образование по доступной цене, соответствующее требованиям мировых стандартов в области образования, бакалавриата, магистратуры, что позволяет проходить стажировки и практику в странах ближнего и дальнего зарубежья. Медина отметила, также об обучении с применением дистанционных технологий.

//       Бейшеналы к Мирана отметила, что, обучаясь в университете, студенты могут получить не только актуальные знания, но и уникальные навыки, необходимые для успешной адаптации в меняющемся мире. В вузе студенты активно принимают участие в молодежных организациях. Университет формирует лидеров, готовых встречать вызовы будущего с уверенностью и получить реальное будущее.

//       Встреча со школьниками прошла с восторгом и впечатлениями. Участники мероприятия были заинтересованы обучением в НИУ КЭУ им. М. Рыскулбекова, были собраны контакты для дальнейшего глубокого ознакомления с университетом в День открытых дверей.`,
//     ],
//     departament: "Кафедра «Философии и социально-гуманитарных наук»",
//   },
// ];

export const Structure = [
  {
    id: 1,
    name: "ПМК Гуманитарно-правовые дисциплины",
    text: "Предметно-методическая комиссия «Гуманитарно-правовые дисциплины» была образована в 2016 году приказом № 266, от 4 сентября. ПМК «Гуманитарно-правовые дисциплины» КЭиС КЭУ им. М.Рыскулбекова выпускает специалистов среднего звена по специальностям 030503«Правоведение», 030504«Право и организация социального обеспечения», 050711«Социальная педагогика».",
    to: "humanitarian-legal-disciplines",
    composition: [
      {
        id: 1,
        fullName: "Джусупова Асель Дженышбековна",
        position: "chairman",
        resume:
          "https://drive.google.com/file/d/1B6DeE7azrfyJ-4WtkFeg_JSQyymjFSdL/view",
      },
      {
        id: 2,
        fullName: "Сабиров Болот Будайбекович",
        position: "teacher",
        resume:
          "https://drive.google.com/file/d/16AOTzeGZh4EZ_m7KDZOHSsrPzEYLmU4h/view",
      },
      {
        id: 3,
        fullName: "Акматова Зарина Салмоорбековна",
        position: "teacher",
        resume:
          "https://drive.google.com/file/d/1uQ413jYZiA-6Gt_9QnEiOHfqD766n67N/view",
      },
      {
        id: 4,
        fullName: "Ахматкалый кызы Назира",
        position: "teacher",
        resume:
          "https://drive.google.com/file/d/1sSUNQkV_sCQoEDrokiL-1vBqtg1QAej2/view",
      },
      {
        id: 5,
        fullName: "Джусубалиева Астра Калыбековна",
        position: "teacher",
        resume:
          "https://drive.google.com/file/d/1Etj7fGxqyTFQxIbL-j4uaNJcZoWGNeyL/view",
      },
      {
        id: 6,
        fullName: "Джумабекова Малика Мамбеткалыевна",
        position: "teacher",
        resume:
          "https://drive.google.com/file/d/1o58WEnwZvyNDtNlWb9AP-K5EmWrxiZQR/view",
      },
      {
        id: 7,
        fullName: "Даниярова Насикат Тилекматовна",
        position: "teacher",
        resume:
          "https://drive.google.com/file/d/19Ph0j2E8CM7wwjVHbHZgcwAEwABISYEb/view",
      },
      {
        id: 8,
        fullName: "Айдралиева Ажар Азиреталиевна",
        position: "teacher",
        resume:
          "https://drive.google.com/file/d/1lAZfk2nkL0XxNI9n2GJReRIjYgyoVg6H/view",
      },
      {
        id: 9,
        fullName: "Мамырканова Гульмира Сейдахматовна",
        position: "teacher",
        resume:
          "https://drive.google.com/file/d/10QYEoox1Px2jE1bEsDamVS6uYytWDCbA/view",
      },
      {
        id: 10,
        fullName: "Ормонкулова Аида Разилбековна",
        position: "teacher",
        resume:
          "https://drive.google.com/file/d/1cRHfFjDeeyJXXienRL-L93HP9RMWzZfL/view",
      },
      {
        id: 11,
        fullName: "Тулебердиева Чинара Насырынбековна",
        position: "teacher",
        resume:
          "https://drive.google.com/file/d/1W9DFM4YwRujJjPb0ZxE4J2txiRndtWzs/view",
      },
      {
        id: 12,
        fullName: "Мирова Сырга Мировна",
        position: "teacher",
        resume:
          "https://drive.google.com/file/d/1i-OuylXrkChD9uT5RGqeR5FYb02nvE5c/view",
      },
    ],
  },
  {
    id: 2,
    name: "ПМК Бухгалтерский учет",
    text: "Предметно-методическая комиссия «Бухгалтерский учет» была образована в 2016 году приказом № 266, от 4 сентября. ПМК «Бухгалтерский учет» КЭиС КЭУ им. М.Рыскулбекова выпускает специалистов среднего звена по специальности 080110 «Экономика и бухгалтерский учет (по отраслям)».",
    to: "accounting",
    composition: [
      {
        id: 1,
        fullName: "Абдрахманова Бермет Манасбековна",
        position: "chairman", // teacher
        resume:
          "https://drive.google.com/file/d/1s2EPejKTThhZMzzCiWI_8PeoMaO6BqHB/view",
      },
      {
        id: 2,
        fullName: "Чекеева Жамал Болотовна",
        position: "teacher",
        resume:
          "https://drive.google.com/file/d/1Y7J8Q60RU91sfRIVsL4zV-tCXDmCONGQ/view",
      },
      {
        id: 3,
        fullName: "Каныбекова Айша Эрнсовна",
        position: "teacher",
        resume:
          "https://drive.google.com/file/d/1wlvpyWRlUYHlCApq02kQ4IqHevFKFita/view",
      },
      {
        id: 4,
        fullName: "Исаева Кыял Абаскановна",
        position: "teacher",
        resume:
          "https://drive.google.com/file/d/1epODwar1knZCOzaZOEucwAu95L_7oGYR/view",
      },
      {
        id: 5,
        fullName: "Аманова Эльнура Тенизбековна",
        position: "teacher",
        resume:
          "https://drive.google.com/file/d/1dKx-0upiLO1iKkljB7Qm7kpxKvqQpwEs/view",
      },
      {
        id: 6,
        fullName: "Мусамамбетова Ырыскүл Зарылбековна",
        position: "teacher",
        resume:
          "https://drive.google.com/file/d/1MSx26_0NlQ_CcyaOvXljHJjI2PQ-4fGE/view",
      },
      {
        id: 7,
        fullName: "Шаадатова Мээрим Шаадатовна",
        position: "teacher",
        resume:
          "https://drive.google.com/file/d/1jSFHtVae0tX2m7cN25WQUZzVsv073Kq8/view",
      },
      {
        id: 8,
        fullName: "Талантбекова Асыл Талантбековна",
        position: "teacher",
        resume:
          "https://drive.google.com/file/d/1E10On7Cs4mmcKLTWycxp5Yj9C94YDWrS/view",
      },
      {
        id: 9,
        fullName: "Эрматова Асылбу Исиевна",
        position: "teacher",
        resume:
          "https://drive.google.com/file/d/18hXo8wIJV8jmaG82hPpCBRr9lyZOh36F/view",
      },
    ],
  },
  {
    id: 3,
    name: "ПМК Финансовые дисциплины",
    text: "Предметно-методическая комиссия «Финансовые дисциплины» была образована в 2016 году приказом № 266, от 4 сентября. ПМК «Финансовые дисциплины» КЭиС КЭУ им. М.Рыскулбекова выпускает специалистов среднего звена по специальностям 080106 «Финансы (по отраслям)», 080108 «Банковское дело», 080107 «Налоги и налогообложение». ",
    to: "financial-disciplines",
    composition: [
      {
        id: 1,
        fullName: "Казакбекова Г.Б.",
        position: "chairman", // teacher
        resume:
          "https://drive.google.com/file/d/13i8VL74v2AvLcF9WAY8lfKOLzJ7Cg0xN/view",
      },
      {
        id: 2,
        fullName: "Ибраева Ж.А.",
        position: "teacher",
        resume:
          "https://drive.google.com/file/d/12Ls8WxVPunZDmeWQ-MXTTtXKPeh0RdF3/view",
      },
      {
        id: 3,
        fullName: "Ташырбаева А.А.",
        position: "teacher",
        resume:
          "https://drive.google.com/file/d/1fNBR5lay_phlwMy8a3k9it4M2ZvZX6uu/view",
      },
      {
        id: 4,
        fullName: "Осмонжанова Ч. А.",
        position: "teacher",
        resume:
          "https://drive.google.com/file/d/1mv5U5LBfDcQGYLCBnztvA3oXWBqXc5Cb/view",
      },
      {
        id: 5,
        fullName: "Эдилбаев К.Ч.",
        position: "teacher",
        resume:
          "https://drive.google.com/file/d/1Cr3YDfJ0DZ0UP7gmbBehOR2-OVNgJIu6/view",
      },
      {
        id: 6,
        fullName: "Молдакунов У. А.",
        position: "teacher",
        resume:
          "https://drive.google.com/file/d/1QQb2r9ckRv_ysQiCSbKMHtfmEQF39Dsa/view",
      },
      {
        id: 7,
        fullName: "Молдоева Т. Т.",
        position: "teacher",
        resume:
          "https://drive.google.com/file/d/1VYa_XzroD9G4o9sSSF3sQeVMhK9Fy6RQ/view",
      },
      {
        id: 8,
        fullName: "Шаменалиева Д. И.",
        position: "teacher",
        resume:
          "https://drive.google.com/file/d/1Z3aGlLbzn0c9aYnBiWzSoYeJ10UgAG0F/view",
      },
      {
        id: 9,
        fullName: "Темирбекова А. Т.",
        position: "teacher",
        resume:
          "https://drive.google.com/file/d/1k4tKW8WaSxH0x5dayKw_mBRHQIyyUe6V/view",
      },
      {
        id: 10,
        fullName: "Армурзаева Д. Э.",
        position: "teacher",
        resume:
          "https://drive.google.com/file/d/1VCbNEY71ybWGwokAk9RgxeNOvrGFtQhw/view",
      },
    ],
  },
  {
    id: 4,
    name: "ПМК Языковые дисциплины и физической культуры",
    text: "Предметно-методическая комиссия «Языковые дисциплины и физической культуры» была образована в 2016 году приказом № 266, от 4 сентября.",
    to: "language-disciplines-and-physical-education",
    composition: [
      {
        id: 1,
        fullName: "Кочорбаева Нургуль Бокоевна",
        position: "chairman", // teacher
        resume:
          "https://drive.google.com/file/d/13WoFJxaAtpvpw-WQkRESsdTa_qmEmGDs/view",
      },
      {
        id: 2,
        fullName: "Туляганова Жазгуль Кимсанбековна",
        position: "teacher",
        resume:
          "https://drive.google.com/file/d/1klIX5OMPdc09ALE4gHDK2A2lQoYzHUZV/view",
      },
      {
        id: 3,
        fullName: "Омурканов Мирлан Токтотемирович",
        position: "teacher",
        resume:
          "https://drive.google.com/file/d/1FDjBrPjX1zRzIB5agbrRHETFGIp-trn0/view",
      },
      {
        id: 4,
        fullName: "Мустафаев Чынгыз Азимович",
        position: "teacher",
        resume:
          "https://drive.google.com/file/d/18aq9FlCwRe2gXru2Dj_bnh0itgx7apbs/view",
      },
      {
        id: 5,
        fullName: "Казакова Сахида Осмоновна",
        position: "teacher",
        resume:
          "https://drive.google.com/file/d/1-CvGZ1571taIJFqbXmBFwRr7uZjDOQN9/view",
      },
      {
        id: 6,
        fullName: "Будайчиева Чынара Касымалиевна",
        position: "teacher",
        resume:
          "https://drive.google.com/file/d/1M56Vjdy5kne67sAmdqfH5FPP6nQsHn10/view",
      },
      {
        id: 7,
        fullName: "Иманалиева Майрамкул Айтбековна",
        position: "teacher",
        resume:
          "https://drive.google.com/file/d/1F5sEzU6K4TlzT9AA9q18Xewgyy5cXteo/view",
      },
      {
        id: 8,
        fullName: "Жузупбек кызы Таннуру",
        position: "teacher",
        resume:
          "https://drive.google.com/file/d/1Dy_EqaOYxuNe6kmwn-QUmX6HlsHMQOeg/view",
      },
      {
        id: 9,
        fullName: "Абдыкадыров Нурзатбек Асхатбекович",
        position: "teacher",
        resume: "",
      },
      {
        id: 10,
        fullName: "Абылова Гулмир Жапаровна",
        position: "teacher",
        resume:
          "https://drive.google.com/file/d/1M76z1CLV2oC3arzIsxhzQrsHcN3iOReq/view",
      },
      {
        id: 11,
        fullName: "Саматбек Нурбек уулу",
        position: "teacher",
        resume: "",
      },
    ],
  },
  {
    id: 5,
    name: "ПМК Технология и торговля",
    text: "Предметно-методическая комиссия была образована еще во времена ФТСТ , после реорганизации 2016 году была переименована как «Технология и торговля», приказом № 266, от 4 сентября 2016 года КЭУ им. М.Рыскулбекова. ПМК «Технология и торговля» выпускает специалистов среднего звена по специальности 260502 «Технология продукции общественного питания». На протяжении многих лет выпустила десятки тысяч техник-технологов в области общественного питания.",
    to: "technology-and-trade",
    composition: [
      {
        id: 1,
        fullName: "Шейшеналиева Н.Т.",
        position: "chairman", // teacher
        resume:
          "https://drive.google.com/file/d/1XXKT4Ykg69MPyu92kCkdD76T3e_Qg66g/view",
      },
      {
        id: 2,
        fullName: "Абдыкадырова Н.А.",
        position: "teacher", // teacher
        resume:
          "https://drive.google.com/file/d/1BSVigC-1-2Hp8mRVH2p76wRurs_4niBi/view",
      },
      {
        id: 3,
        fullName: "Есенбаева Г.М.",
        position: "teacher", // teacher
        resume:
          "https://drive.google.com/file/d/1sXQavPNbCSSQWsaqF-uGrDKqqJv5coMo/view",
      },
      {
        id: 4,
        fullName: "Суйунбекова Н.С.",
        position: "teacher", // teacher
        resume:
          "https://drive.google.com/file/d/11i_c0xCTGLDwWsE9TdbW3qLBuUWR-b23/view",
      },
      {
        id: 5,
        fullName: "Бектурова А.К.",
        position: "teacher", // teacher
        resume:
          "https://drive.google.com/file/d/1IFRQy0FdLO1nOOh-16RRx4S9WQayZYDM/view",
      },
      {
        id: 6,
        fullName: "Кожоянова С.А.",
        position: "teacher", // teacher
        resume:
          "https://drive.google.com/file/d/1RMr4t5sdvuCm0JWmPzYa3bFxaRo962D_/view",
      },
      {
        id: 7,
        fullName: "Козлова С.В.",
        position: "teacher", // teacher
        resume:
          "https://drive.google.com/file/d/1VfXaRoIc2XMzuQ1Y0MHNKcX8FPWneSth/view",
      },
    ],
  },
  {
    id: 6,
    name: "ПМК Информатика и естественнонаучные дисциплин",
    text: "Предметно-методическая комиссия «Информатика и естественнонаучные дисциплин» была образована в 2016 году приказом № 266, от 4 сентября. ПМК «Информатика и естественнонаучные дисциплин» КЭиС КЭУ им. М.Рыскулбекова выпускает специалистов среднего звена по специальности 230701«Прикладная информатика (по отраслям)».",
    to: "computer-science-and-natural-sciences",
    composition: [
      {
        id: 1,
        fullName: "Токтобай кызы Касиет",
        position: "chairman", // teacher
        resume:
          "https://drive.google.com/file/d/1iCDxu4Y1GCd2iysFNg6hng-2LrN54f1s/view?usp=sharing",
      },
      {
        id: 2,
        fullName: "Арстанбекова Айсулуу Калыбековна",
        position: "teacher",
        resume:
          "https://drive.google.com/file/d/1Z89rLlKV9NiANSAdU6j3gcIrHlhwpJGL/view?usp=sharing",
      },
      {
        id: 3,
        fullName: "Дадабаева Гаухар Каримджановна",
        position: "teacher",
        resume:
          "https://drive.google.com/file/d/1NU6pUzc3HByGmzpZxXfLWP890ArX1VN2/view?usp=sharing",
      },
      {
        id: 4,
        fullName: "Айдарбек кызы Элнура",
        position: "teacher",
        resume:
          "https://drive.google.com/file/d/1gGchjl5cdgY_gvIEQLzZk317X9kHPEqr/view?usp=sharing",
      },
      {
        id: 5,
        fullName: "Тонуева Динара Иманбековна",
        position: "teacher",
        resume:
          "https://drive.google.com/file/d/1GXgO-7Jlppe3it4lhjeRaFBYFxbOhZ5U/view?usp=sharing",
      },
      {
        id: 6,
        fullName: "Назарбекова Ханзада Назарбековна",
        position: "teacher",
        resume:
          "https://drive.google.com/file/d/1HyQLB4Bhcq9KJEunPMDYxW0E_wKpCukW/view?usp=sharing",
      },
      {
        id: 7,
        fullName: "Кочконбаева Акбермет Кочконбаевна",
        position: "teacher",
        resume:
          "https://drive.google.com/file/d/1X-jkve0MmjqE4WTsuFRgk8lvJaGgGmLy/view?usp=sharing",
      },
      {
        id: 8,
        fullName: "Турсунбекова Гулжазы Рысбековна",
        position: "teacher",
        resume:
          "https://drive.google.com/file/d/1w8bxBv581r0EJ-Jdvg9J-8TiWaynZqnP/view?usp=sharing",
      },
      {
        id: 9,
        fullName: "Шергазиева Кенже Дуйшенбековна",
        position: "teacher",
        resume:
          "https://drive.google.com/file/d/1WSZif_1VBIuOLkHLS4jrCHtRniIyNixP/view?usp=sharing",
      },
      {
        id: 10,
        fullName: "Кубанычбек кызы Алтынбубу",
        position: "teacher",
        resume:
          "https://drive.google.com/file/d/1VeO2LX_Vq8TEW24ZYdBur0tw5VabDoSY/view?usp=sharing",
      },
    ],
  },
  {
    id: 7,
    name: "ПМК Экономика, менеджмент и туризм",
    text: "Предметно-методическая комиссия «Экономика, менеджмент и туризм» была образована в 2016 году приказом № 266, от 4 сентября. ПМК «Экономика, менеджмент и туризм» КЭиС КЭУ им. М.Рыскулбекова выпускает специалистов среднего звена по специальностям 100105 «Организация обслуживания в гостиницах и туристических комплексах», 100201«Туризм», 080501«Менеджмент (по отраслям)». ",
    to: "economics-management-and-tourism",
    composition: [
      {
        id: 1,
        fullName: "Муктарбекова Сыяда Жолдошбековна",
        position: "chairman", // teacher
        resume:
          "https://drive.google.com/file/d/1RxO9sTmI2FfGuU5TZP34yH0Hkf9rm7DM/view",
      },
      {
        id: 1,
        fullName: "Абыкеева Жыпара Эшеновна",
        position: "teacher",
        resume:
          "https://drive.google.com/file/d/1fxueSx4x5k6qAUFIQB2AMsYrm_vX5sRm/view",
      },
      {
        id: 1,
        fullName: "Кыдыралиева Мээрим Эриковна",
        position: "teacher",
        resume:
          "https://drive.google.com/file/d/1BUnwyR4ejP1v5G1TA82WAp11Dy2ewPtF/view",
      },
      {
        id: 1,
        fullName: "Асан теги Алтынкуль",
        position: "teacher",
        resume:
          "https://drive.google.com/file/d/1cKYjiLDvK4AyhaBkkQmM2mpmpWjtScMU/view",
      },
      {
        id: 1,
        fullName: "Кубанычбекова Айкерим Алмасбековна",
        position: "teacher",
        resume:
          "https://drive.google.com/file/d/1JPgB_U6yKkBUN_3M8qfu_EMZ1dBiq_3b/view",
      },
      {
        id: 1,
        fullName: "Эсенаманова Аида Анарбековна",
        position: "teacher",
        resume:
          "https://drive.google.com/file/d/19ChH6G-3VMp-9PcN497neuN6e0DXrma7/view",
      },
      {
        id: 1,
        fullName: "Атышова Жыпар Исланбековна",
        position: "teacher",
        resume:
          "https://drive.google.com/file/d/1vr_sWwkSyDmqXG3sdQ8ZDFwsYzNXZ9zM/view",
      },
      {
        id: 1,
        fullName: "Шеке кызы Гулжамал",
        position: "teacher",
        resume:
          "https://drive.google.com/file/d/1uhMoVJk_Ig_fidDjACMKTS4OvkMg6ehn/view",
      },
      {
        id: 1,
        fullName: "Грамоты и сертификаты ППС",
        position: "teacher",
        resume:
          "https://drive.google.com/file/d/1D1i0w9-t1axo8m0t3vdXVIlctdxLUVLU/view",
      },
    ],
  },
  {
    id: 8,
    name: "Лицей",
    text: "",
    to: "lyceum",
    composition: [
      {
        id: 1,
        fullName: "Узбекова Гульнара Ашырбековна",
        postName: "Директор лицея",
        position: "chairman",
        resume:
          "https://drive.google.com/file/d/1FDYKgvTrTfd7elLHLeZI7mPDI96-Gppm/view",
      },
      {
        id: 2,
        fullName: "Джолдошалиева Зульфия Наримановна",
        postName: "Зам.директора по ВР",
        resume: "#",
        position: "chairman",
      },
      {
        id: 3,
        fullName: "Кутанова Бактыгуль Алыбековна",
        postname: "Зам.директора по УР",
        resume: "#",
        position: "chairman",
      },
      {
        id: 4,
        fullName: "Бактыбекова Бегайым Бактыбековна",
        postName: "Зав. уч. частью",
        resume: "#",
        position: "chairman",
      },
      {
        id: 5,
        fullName: "Ааламбек кызы Чолпонай",
        postName: "",
        resume:
          "https://drive.google.com/file/d/1vzV_8plB5dTgehDhiHtiX1IMeZ9rqFEm/view",
        position: "teacher",
      },
      {
        id: 6,
        fullName: "Абдразакова Нуржамал Мадалиевна",
        postName: "",
        resume:
          "https://drive.google.com/file/d/1JlJoj7sYxdhIB3H9SAcgw6oL2_g2lHHA/view",
        position: "teacher",
      },
      {
        id: 7,
        fullName: "Абдыбекова Калбубу Мамыровна",
        postName: "",
        resume:
          "https://drive.google.com/file/d/172GJyndQyfianhy2lssnK_U8mo2H0HLy/view",
        position: "teacher",
      },
      {
        id: 8,
        fullName: "Абдырахманова Айжамал Абдырахмановна",
        postName: "",
        resume:
          "https://drive.google.com/file/d/1NpVvpc7TvHIWwp1t0-ovmE-dsYOkioB-/view",
        position: "teacher",
      },
      {
        id: 9,
        fullName: "Акматбекова Мактым Бектеналиевна",
        postName: "",
        resume:
          "https://drive.google.com/file/d/13IrPctp6nrqbhWb1N5YlJ0vhjv8dmiBB/view",
        position: "teacher",
      },
      {
        id: 10,
        fullName: "Ахмеджанова Зайнаб Шавкатовна",
        postName: "",
        resume:
          "https://drive.google.com/file/d/1lQAm466R273pNvUmpqfEv0x__Sne68js/view",
        position: "teacher",
      },
      {
        id: 11,
        fullName: "Ахметова Салтанат Эсеркеповна",
        postName: "",
        resume: "#",
        position: "teacher",
      },
      {
        id: 12,
        fullName: "Байсалиев Эдилбек Капарович",
        postName: "",
        resume:
          "https://drive.google.com/file/d/15qqkDsiLJJ-KMF8TdCgHWc32L6rh3SyA/view",
        position: "teacher",
      },
      {
        id: 13,
        fullName: "Бальджикова Инна Валентиновна",
        postName: "",
        resume:
          "https://drive.google.com/file/d/1Jky0zst2lC3YbroQq9RIi_mKZ4pH2J9Y/view",
        position: "teacher",
      },
      {
        id: 14,
        fullName: "Боколова Салтанат Денизбековна",
        postName: "",
        resume: "#",
        position: "teacher",
      },
      {
        id: 15,
        fullName: "Борубаев Азизбек Асанбекович",
        postName: "",
        resume: "#",
        position: "teacher",
      },
      {
        id: 16,
        fullName: "Горшков Валерий Александрович",
        postName: "",
        resume:
          "https://drive.google.com/file/d/1dRHUqRX_alKYnSKGhkjvasc4oCuszykh/view",
        position: "teacher",
      },
      {
        id: 17,
        fullName: "Казиева Сейил Мырзабековна",
        postName: "",
        resume:
          "https://drive.google.com/file/d/1PdAy_iGYpp54prHC_dBHp6_Yzzn7XnVk/view",
        position: "teacher",
      },
      {
        id: 18,
        fullName: "Касымалиева Гульнара Абдыкадыровна",
        postName: "",
        resume:
          "https://drive.google.com/file/d/1SU4OLOLCArTfXZF22N7VRrEReBG3OQCl/view",
        position: "teacher",
      },
      {
        id: 19,
        fullName: "Кенжебаева Гульнур Акматалиевна",
        postName: "",
        resume:
          "https://drive.google.com/file/d/1GqL8oHnYAPt3jLG_88T4-UWcqCgoai7u/view",
        position: "teacher",
      },
      {
        id: 20,
        fullName: "Кубанычбекова Зуаида Соотбековна",
        postName: "",
        resume:
          "https://drive.google.com/file/d/1Vf0yZ0gOMCtUoEwCCt4gS_4_RKQB70jf/view",
        position: "teacher",
      },
      {
        id: 21,
        fullName: "Машарипов Азирет Уларбекович",
        postName: "",
        resume:
          "https://drive.google.com/file/d/1UOiWT0UjNsbiINMQQbvNXPUI9iWMW2Ni/view",
        position: "teacher",
      },
      {
        id: 22,
        fullName: "Мурзабаева Динар Асанбековна",
        postName: "",
        resume:
          "https://drive.google.com/file/d/1UA6RtpUzAgZDUApZagI3K9OLbMvlWxzA/view",
        position: "teacher",
      },
      {
        id: 23,
        fullName: "Назарбаева Керез Турдуманбетовна",
        postName: "",
        resume:
          "https://drive.google.com/file/d/1YWwtvU6MoPZLPIHtAnn0c_Yvs-FhH19O/view",
        position: "teacher",
      },
      {
        id: 24,
        fullName: "Нурмамбетова Венера Нуркалиевна",
        postName: "",
        resume: "#",
        position: "teacher",
      },
      {
        id: 25,
        fullName: "Орозбекова Назгуль Орозбековна",
        postName: "",
        resume:
          "https://drive.google.com/file/d/1md2L-V_qB1Z9fxz613hGRKAO6mZXa9u1/view",
        position: "teacher",
      },
      {
        id: 26,
        fullName: "Осмоналиева Гульнура Шайлообековна",
        postName: "",
        resume:
          "https://drive.google.com/file/d/1JZvPNCqCWYQX1-0aMjeH9aDVvRbZJy6C/view",
        position: "teacher",
      },
      {
        id: 27,
        fullName: "Оторбаева Гульнур Дуюшенбиевна",
        postName: "",
        resume: "#",
        position: "teacher",
      },
      {
        id: 28,
        fullName: "Сагынбекова Айданек Уланбековна",
        postName: "",
        resume:
          "https://drive.google.com/file/d/1D_AKg9gcCDe90yr9i4mxsTdzBgRqbrtI/view",
        position: "teacher",
      },
      {
        id: 29,
        fullName: "Сагындыкова Айчурок Абдыманаповна",
        postName: "",
        resume: "#",
        position: "teacher",
      },
      {
        id: 30,
        fullName: "Шакирова Марина Алековна",
        postName: "",
        resume:
          "https://drive.google.com/file/d/1UAZd6hQy_LS5m6SclGfGtf46K01QxSbO/view",
        position: "teacher",
      },
    ],
  },
];

export const StructureTwo = [];

export const OurBenefits = [
  {
    id: 1,
    title: "Программа обучения и преподавательский состав",
    text: "В нашем колледже студенты получают фундаментальные теоретические знания и практические умения, необходимые для успешной карьеры. Свои знания и опыт студентам передают высококвалифицированные преподаватели, и опытные специалисты с производства.",
  },
  {
    id: 2,
    title: "Выпускные дипломы и карьерные перспективы",
    text: "Успешно закончившие обучение, выпускники, получают диплом государственного образца о среднем профессиональном образовании, а вместе с ним получают широкий кругозор, профессиональные навыки, уверенность в себе.",
  },
  {
    id: 3,
    title: "Возможности продолжения обучения после колледжа",
    text: "По окончании колледжа наши выпускники также имеют возможность продолжить обучение в высших учебных заведениях.",
  },
  {
    id: 4,
    title: "Информационная система Е-билим и автоматизация обучения",
    text: "Автоматизацию обучения студентов обеспечивают Информационная система Е-билим.",
  },
  {
    id: 5,
    title: "Студенческая жизнь: фестивали, встречи, соревнования",
    text: "Кроме главной задачи - учёбы, в колледже насыщенная студенческая жизнь. У нас традиционно проходят различные фестивали, ежегодные Декады Знаний, тематические встречи и вечера, соревнования, праздники, организованные самими студентами и др.",
  },
  {
    id: 6,
    title: "Творческая, спортивная и общественная деятельность студентов",
    text: "У нас есть всё необходимое для самореализации студентов в творческой, спортивной и общественной деятельности. Ребята принимают участие в различных творческих конкурсах, спортивных соревнованиях, молодежных форумах, вебинарах.",
  },
  {
    id: 7,
    title: "Адаптация и конкурентоспособность выпускников",
    text: "Благодаря этому, выпускники нашего колледжа являются творческими конкурентоспособными специалистами, которые могут активно и эффективно работать в постоянно меняющихся социальных и экономических условиях современного общества.",
  },
  {
    id: 8,
    title: "Условия проживания для иногородних студентов",
    text: "Иногородние студенты колледжа обеспечиваются местом в общежитии, где созданы все необходимые условия для проживания в соответствии с санитарными нормами и правилами: благоустроенные комнаты; изолятор; кухня, оборудованная электрическими плитами, мойкой с горячей и холодной водой, холодильником, стиральным автоматом, мультиваркой, микроволновкой; имеются отдельные санузлы и душевая комната.",
  },
  {
    id: 9,
    title: "Организация жизни в общежитии: воспитатели и контроль",
    text: "В общежитии работает воспитатель и действует контрольно–пропускной режим.",
  },
  {
    id: 10,
    title: "Безопасность на территории колледжа",
    text: "Для обеспечения безопасности студентов, преподавателей и сотрудников в колледже: ведется видеонаблюдение; установлена пожарная сигнализация.",
  },
  {
    id: 11,
    title: "Процесс поступления: критерии и сроки",
    text: "Поступление в колледж проводится  по результатам среднего балла аттестата о среднем образовании (11 кл.) или свидетельства об основном общем образовании (9 кл.). Прием документов начинается с 12 июля 2023 г.",
  },
];

export const NewsArr = [
  {
    id: 1,
    img: news1,
    title: "Ознакомительно-профориентационная работа",
    subtitle: "",
    date: "",
    text: `Для студентов группы "Менеджмент" (3 курс) в главном корпусе КЭУ была проведена ознакомительно-профориентационная работа для продолжения обучения по программе бакалавриата после выпуска колледжа.`,
    departament: "",
  },
  {
    id: 2,
    img: news2,
    title:
      "Компьютердик, автоматтык жана кумар оюндардын зыяндуулугу, Лудомания коркунучтуу оору",
    subtitle: "",
    date: "",
    text: "2023-жылдын 10 февралында М. Рыскулбеков атындагы Илимий изилдөө университети, Кыргыз экономикалык университетинин Экономика жана сервис колледжинде, Кыргыз Республикасынын Билим берүү жана илим министирлигинин 23 январындагы №31/1 буйругунун аткаруу максатында, КЭУнун чоң жыйындар залында колледждин бардык адистиктеринде билим алып жаткан студенттерине, «Компьютердик, автоматтык жана кумар оюндардын зыяндуулугу, Лудомания коркунучтуу оору», темасындагы көлөмдүү лекция Э. Алиев атындагы Ички иштер министирлининин Академиясынын Кылмыш жаза кафедрасынын доценти Ажыгулов Мирбек Токторбекович тарабынын болуп өттү. Азыркы учурда жаш муун, кумар, автоматтык оюндарга кызыгуусу көбөйүп жаткандыгы баардыгыбызга абзел, ошол көйгөйдү лектор статистикалык маалыматка, фактыларга таянып, абдан кызыктуу түшүндүрүп берди. Иш-чаранын алкагында, купуя тестирлөө өткөрүлдү, студенттер лекцияны кызыгуу менен кабыл алып, бул маселе абдан чоң суроо жаратып лекция өз максатына жетти.    ",
    departament: "",
  },
  {
    id: 3,
    img: news3,
    title: "Hard skills и Soft skills - что важнее?",
    subtitle: "",
    date: "",
    text: '9 февраля 2023г в КЭиС для студентов групп СС-1-21, СС-1-22 был проведён тренинг преподавателем психологии Тулебердиевой Чинарой Насырынбековной на тему : "Hard skills и Soft skills - что важнее?" Цель тренинга: мотивировать студентов развивать в себе коммуникативные навыки, эмпатию, креативность, ораторское мастерство, общую эрудицию и постоянное стремление учиться новому.',
    departament: "",
  },
  {
    id: 4,
    img: news4,
    title: "Мотивация в социальной работе",
    subtitle: "",
    date: "",
    text: 'Студенты специальности "Социальная педагогика" вместе с преподавателем Казаковой С.О. посетили факультет социально-гуманитарных наук КНУ им. Ж.Баласанына. Лектор- преподаватель кафедры Шайлдаева Асель провела лекцию для будущих специалистов на тему "Мотивация в социальной работе". Студенты получили много полезной информации о будущей профессии и поблогадарили лектора за содержательную лекцию.',
    departament: "",
  },
  {
    id: 5,
    img: news5,
    title: "Учебно-производственная экскурсия",
    subtitle: "",
    date: "",
    text: '14 февраля для студентов колледжа специальностей "Туризм", "Менеджмент" и "Организация обслуживания в гостиницах и туркомплесах" была проведена учебно-производственная экскурсия в один из лучших отелей столицы "Park Hotel" для повышения интереса в профессии и ознакомлении работы отеля. Выражаем благодарность руководству и сотрудникам parkhotel_kg за вклад в развитие будущих специалистов!',
    departament: "",
  },
  {
    id: 6,
    img: news6,
    title: "Научно-практическая конференция",
    subtitle: "",
    date: "",
    text: '27–28 октября 2022 т.г. в Ташкенте прошла первая региональная научно-практическая конференция «Региональные аспекты устойчивого развития профессионального образования: вызовы и перспективы» с участием европейских и центральноазиатских экспертов в сфере профессионального образования, бизнеса и производства, государственной и общественной деятельности. Преподаватель Кыдыралиева М.Э. приняла участие в конференции и выступила с докладом на тему "Геймификация в образовании" от лица КЭиС НИУ КЭУ.',
    departament: "",
  },
  {
    id: 7,
    img: news7,
    title: "Профессия, специальность, квалификация",
    subtitle: "",
    date: "",
    text: `15 февраля 2023 года, преподаватели образовательной программы «Социальная педагогика», «Право и организация социального обеспечения», «Правоведение» - Казакова С.О., Мамырканова Г.С., Акматова З.С. посетили колледж Таласского государственного университета по академической мобильности согласно заключенному договору.
    Для студентов специальности «Социальная педагогика» была проведена лекция на тему «Профессия, специальность, квалификация» в рамках дисциплины «Введение в специальность» преподавателем Казаковой Сахидой Осмоновной. Данная тема раскрывает общую характеристику педагогической профессии, ее значении и роли в обществе, описывает возникновение и развитие педагогической профессии.
    Лекцию на тему «Основы права» рассказала преподаватель Мамырканова Гульмира Сейдахматовна студентам-правоведам 1 курса. Цель занятия – формирование и развитие правового сознания, правовой культуры студентов, как граждан Кыргызстана. Лекция проходила под девизом: «Незнание законов не освобождает от ответственности!» (лат. «Ignorantia juris non excusat»). При проведении занятия были использованы технические средства обучения (ноутбук, видеопроектор).
    Преподаватель Акматова Зарина Салмоорбековна провела лекцию студентам 2 курса специальности «Правоведение» по дисциплине «Конституционное право» на тему «Права и обязанности человека и гражданина». Лекция проходила в форме интерактивной игры под названием «Конкурс знатоков права». Группа была разделена на три команды, каждой были даны ситуационные задания. Цели занятия - формирование представления обучающихся о необходимости защиты прав человека, формирование умений выделять ключевые положения, навыков высказывать аргументированные суждения и умозаключения, делать выводы.
    С администрацией колледжа Таласского государственного университета была проведена встреча, в результате которой договорились о дальнейшем сотрудничестве в области академической мобильности студентов и преподавательского состава.`,
    departament: "",
  },
  {
    id: 8,
    img: news8,
    title: "Система управления",
    subtitle: "",
    date: "",
    text: `Для студентов колледжа регулярно проводятся встречи с представителями различных организаций и компаний, и организуются гостевые лекции. Цель таких встреч состоит в непосредственном общении с практиками, знакомство с опытом специалистов в различных сферах. Вот и на этой неделе состоялась еще одна интересная гостевая лекция от эксперта по менеджменту Карыбеков Азамат Нурлановичazamat_myrza_karybekov и совладельца nextgeneration.kg на тему "Система управления". Были затронуты такие аспекты как систематизированный набор средств для управления подконтрольным объектом: возможность сбора показаний о его состоянии, а также средств воздействия на его поведение, предназначенный для достижения заданных целей. Встреча прошла оживленно и интересно с вовлечением студентов в дискуссию, где были получены ответы на вопросы аудитории. Информация, прозвучавшая в ходе гостевой лекции оказалась очень полезной для студентов.
    Студенты выразили благодарность приглашенным гостям за сотрудничество и возможность прямого общения с ними. Считаем, что данный формат расширяет возможности получения знаний и непосредственно влияет на профориентацию студентов.`,
    departament: "",
  },
];

export const AddsArr = [
  {
    id: 1,
    img: add1,
    title: "Дорогие студенты КЭУ!",
    subtitle: "",
    date: "",
    text: `Клуб профессионалов ProKG объявляет набор среди молодых людей от 19 до 23 лет  на ежегодный мега-образовательный проект“Программа Лидерства”!

    Приглашаем вас на презентацию Программы Лидерства, которая состоится в Кыргызском Экономическом Университете (КЭУ) им. М. Рыскулбекова 16-го февраля, 2018 г., пятница, в 12:30 в большом актовом зале (БАЗ)`,
    departament: "",
  },
  {
    id: 2,
    img: add2,
    title:
      "Администрация КЭиС поздравляет всех преподавателей с наступающим праздником",
    subtitle: "",
    date: "",
    text: `Международным Женским днем!!!`,
    departament: "",
  },
  {
    id: 3,
    img: add3,
    title: "Вручение дипломов выпускникам 3 курсов",
    subtitle: "",
    date: "",
    text: `Завтра в 10.07.18 в 10:00 Состоится вручение дипломов выпускникам 3 курсов  КЭиС`,
    departament: "",
  },
  {
    id: 4,
    img: add5,
    title:
      "Конкурс на лучшее эссе среди студентов на тему «Азартные игры и их влияние на личность и общество»",
    subtitle: "",
    date: "",
    text: `Конкурс на лучшее эссе среди студентов на тему «Азартные игры и их влияние на личность и общество», направлен на формирование в обществе политики нетерпимости к азартным играм, информировании об отрицательных последствиях лудомании и преимуществах здорового образа жизни.

    Конкурс проводится во исполнение Указа президента от 23 декабря 2022 года «О мерах по пресечению незаконной деятельности по организации и проведению азартных игр».
  
  Требования к эссе:
  
  Конкурсная работа должна быть выполнена на кыргызском или русском языках. На титульном листе указываются: полное наименование образовательной организации, Ф.И.О. обучающегося, класс, тема сочинения (эссе), контактные данные (номер телефона). Работа должна быть выполнена обучающимися самостоятельно, ранее не представленной на другие конкурсы.
  Количество работ, принимаемых к рассмотрению от одного участника – не более 1(одной) работы. Не допускаются к участию в конкурсе и не рассматриваются работы не соответствующие условию конкурса, коллективные и анонимные работы (не содержащие информацию об участнике конкурса). Конкурсные материалы, поданные позднее назначенного срока, не принимаются и не рассматриваются.
  Конкурсная работа должна быть оформлена следующим образом: максимальный объем сочинения (эссе) до 3-х (трех) листов, используемый шрифт Times New Roman, размер шрифта 14, с межстрочным интервалом 1,15.
  В конкурсе могут принять участие студенты бакалавриата, колледжа и лицея.
  Награждение состоится 17-февраля 2023 года. Работы принимаются до 16 февраля 2023 года и необходимо направить эссе на электронную почту aygul.nurmatova@mail.ru с пометкой в теме письма «Азартные игры и их влияние на личность и общество».
  
  Справки по телефону: +996702051408.
  
  Организаторами конкурса являются: Департамент маркетинга и молодежной политики.`,
    departament: "",
  },
  {
    id: 5,
    img: add6,
    title: "Подготовка к ОРТ от НИУ КЭУ им М.Рыскулбекова",
    subtitle: "",
    date: "",
    text: `Успешная сдача ОРТ является пропуском в любой ВУЗ Кыргызстана и за рубежом. Поэтому, курс «Подготовка к ОРТ по математике» рассчитан на подготовку учащихся к данным испытанием и выбора профессии. Занятие проводится в течение три месяца.  Программа рассчитана на 2 часа в неделю.  

    В процессе изучение данного курса предполагает использование различных методов активизации познавательной деятельности школьников, а также различных форм организации их самостоятельной работы и проводится пробный тест ОРТ в НИУ КЭУ им. М. Рыскулбекова.
    
    Адрес: г. Бишкек, ул. Тоголок Молдо 58, каб.103, телефон: 0553045509, 0509569091`,
    departament: "",
  },
  {
    id: 6,
    img: add7,
    title: "Түрк тили курсуна катталуу жүрүүдө...",
    subtitle: "",
    date: "",
    text: `Урматтуу студенттер жана мугалимдер! Түрк тили курсуна катталуу жүрүүдө..

    Түркиядан келген мугалимдер;
    
    Ар кандай курактагы студенттер;
    
    Окуу бекер, окуу материалдары үчүн гана 2000 сом төлөшүңүз керек;
    
    Сабактар ​​дүйшөмбүдөн жумага чейин өткөрүлөт;
    
    Сабактын узактыгы 1,5 саат;
    
    Алгачкы сабак 16-январь саат 15.00те, 215-аудиторияда өткөрүлөт.
    
    Жыйынтыгында, сиздин тилди өздөштүрүү деңгээлиңизди тастыктаган сертификат берилет.
    
    Баардык суроолор боюнча төмөнкү телефондорго кайрылыңыз: 0772 467 304, 0500 467 304`,
    departament: "",
  },
  {
    id: 7,
    img: add8,
    title:
      "Открыт набор на стипендиальную программу International Excellence Scholarship Program. Срок: до 30 декабря",
    subtitle: "",
    date: "",
    text: `Уважаемые студенты, магистранты и аспиранты!

    Мы рады сообщить, что открыт набор на стипендиальную программу International Excellence Scholarship Program.
    Срок: до 30 декабря
    Для получения информации нажмите https://www.keu.kg/site/%C2%A0https://en.copernicusberlin.de/ies
    Также, для дополнительной информацией обратитесь в международный отдел КЭУ, 308 каб.`,
    departament: "",
  },
  {
    id: 8,
    img: add9,
    title:
      "Объявляем сбор вещей для передачи в детский дом для сирот и дом престарелых.",
    subtitle: "",
    date: "",
    text: `Объявляем сбор вещей для передачи в детский дом для сирот и дом престарелых.

    Если у вас  имеются ненужные вещи, просим на выходных подготовить и принести с собой в НИУ КЭУ.
    
    В течение следующей недели в фойе будут стоять коробки с соответствующими надписями.
    
    Также приветствуется мыломоющие средства всех видов.`,
    departament: "",
  },
  {
    id: 9,
    img: add10,
    title:
      "Культурный Центр «Рух Ордо» объявляет набор сотрудников на должность гидов-экскурсоводов на лето 2023 года.",
    subtitle: "",
    date: "",
    text: `Объявляем набор сотрудников на должность гидов-экскурсоводов на лето 2023 года.

    Требования к кандидатам: 
    ▪︎ СТУДЕНТЫ 
    ▪︎ знание/владение кыргызским и/или русским языком, 
    ▪︎ знание английского и дополнительных языков приветствуется, 
    ▪︎ знание истории Кыргызстана приветствуется,
    ▪︎ ответственность и желание работать.
    
    Энергичные, креативные, коммуникабельные, желающие получить ценный опыт и возможность общения с интересными людьми - вступайте в нашу большую и дружную команду! 
    
    Обучение проходит в г. Бишкек 
    
    Резюме с фото высылайте на e-mail: rukhordohr@gmail.com.
    По всем вопросам, пожалуйста, пишите на вотсап: +996 777 922 730.
    
     
    
    Центр Карьеры, Стажировки и вакансии`,
    departament: "",
  },
  {
    id: 10,
    img: add11,
    title:
      "Международная онлайн-стажировка с 20 февраля по 01 марта 2023 года на базе образовательной онлайн-платформы SKLAD",
    subtitle: "",
    date: "",
    text: `Международная онлайн-стажировка с 20 февраля по 01 марта 2023 года на базе

    образовательной онлайн-платформы SKLAD
    
    «Образовательная среда без буллинга: международные подходы к предотвращению
    
    издевательств»
    
    В связи с этим изучение международного опыта по предотвращению издевательств в
    образовательной среде будет способствовать изучению международных стандартов и
    практик по эффективному противодействию буллингу, пониманию природы и последствий
    насилия, а также механизмов ответа на него и формированию личной стратегии педагога
    для создания безопасной и поддерживающей среды обучения.
    
    
    В рамках программы стажировки международные эксперты из Финляндии, Швеции,
    Италии, Великобритании, Ирландии, США, Канады, Бразилии и Австралии представят
    лучшие международные практики по борьбе с издевательствами и их предотвращению,
    рассмотрят факторы, влияющие на уязвимость к школьному насилию и издевательствам,
    представят прикладные программы, которые входят в ТОП-10 по предотвращению
    буллингу (KiVA Anti- Bullying Program, Olweus Anti-Bullying Program, P.E.A.C.E. pack,
    Escola Sem Bullying, Dare to Care, Positive Action) и ресурсы для преподавателей,
    обеспечивающих безопасную психологическую и физическую среду в учреждении
    образования.
    
    Особое внимание в ходе стажировки будет уделено следующим вопросам:
    - роль учителей в предотвращении и борьбе с буллингом и издевательствами в школе
    -обучение и поддержка учителей по борьбе с издевательствами, а также ориентированное
    на учащихся и заботливое управление классом
    - учебная программа и обучение для создания антибуллингового школьного климата
    - способы обеспечения безопасной психологической и физической среды в школе и классе
    -примеры программ, включающих элементы комплексного подхода (общешкольный
    подход)
    - факторы, влияющие на уязвимость к школьному насилию и издевательствам
    - механизмы обратной связи для учащихся, пострадавших от издевательств
    - эффективные практические методы и инициативы по борьбе с издевательствами и их
    предотвращению.
    
    
    Приглашаем к участию в стажировке: руководителей, педагогов
    профессиональных учреждений образования, педагогов-психологов, социальных
    педагогов, преподавателей и студентов педагогических ВУЗов и колледжей, специалистов
    областных, городских, районных отделов образования, родителей и других
    заинтересованных лиц.
    
    
    Рабочие языки: русский, английский
    Формат проведения: онлайн на платформе ZOOM
    Варианты участия: индивидуально (1 человек-20EUR) и коллективно (группа от 10
    человек и более - 150 EUR).
    
    
    По окончании международной онлайн-стажировки выдается электронный сертификат
    участника с приложением 72 часа.
    
    
    Для участия в международной онлайн-стажировке необходимо до 17 февраля
    направить заявку (Приложение 3) на электронную почту centercareer58@gmail.com
    
    По возникающим вопросам можно обратиться по телефону +996 559690002 (WhatsApp).
    Контактное лицо в Кыргызстане: Джумагулова Акылай Анаркуловна, директор “Центра
    карьеры”.`,
    departament: "",
  },
];
