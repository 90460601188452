import React, { useEffect, useState } from "react";
// ---- Style ---- //
import "./index.scss";
import NewsCard from "../NewsCard";
import { APILINK } from "../../constants";
import axios, { all } from "axios";
import { NewsArr, AddsArr } from "../../constants";
import { useLocation } from "react-router-dom";

// ---- Components ---- //

const AllNewsPage = () => {
  const [allNews, setAllNews] = useState(null);
  const currentURL = useLocation();
  const page = currentURL.pathname.split("/")[1];

  const getNews = async () => {
    try {
      const response = await axios.get(`${APILINK}/news`);
      const reverseNews = response.data.reverse();
      if (page === "news") {
        setAllNews([...reverseNews, ...NewsArr]);
      } else if (page === "adds") {
        setAllNews([...reverseNews, ...AddsArr]);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getNews();
  }, []);

  return (
    <div className="all-news-page">
      <div className="container">
        <h2 className="all-news-page__title title">
          {page === "news" ? "Новости" : "Объявления"}
        </h2>
        <div className="all-news-page__cards">
          {!allNews ? (
            <p>Loading...</p>
          ) : (
            allNews.map((news, indx) => (
              <NewsCard news={news} indx={indx} page={page} />
            ))
          )}
        </div>
      </div>
    </div>
  );
};

export default AllNewsPage;
