import React from "react";
// ---- Style ---- //
import "./index.scss";
import { FaInstagram } from "react-icons/fa6";
import { FaFacebook, FaWhatsapp, FaYoutube } from "react-icons/fa";

// ---- Components ---- //

const Footer = () => {
  return (
    <footer className="footer">
      <div className="container">
        <div className="footer__content">
          <ul className="footer__contacts">
            <li className="footer__item">Контакты/Адрес</li>
            <li className="footer__item">
              Адрес: г. Бишкек, пр-т Манаса, 113 720033
            </li>
            <li className="footer__item">
              <a href="tel:996312323152">+996(312)32-31-52</a>
            </li>
            <li>
              <a href="tel:996772527256">+996(772)52-72-56</a>
            </li>
            <li className="footer__item">
              <a href="mailto:college@keu.ru">college@keu.ru</a>
            </li>
          </ul>

          <ul className="footer__socials">
            <li className="footer__social">
              <a href="https://www.instagram.com/keu_college?igsh=amEyZzdzOHl2eDM2">
                <FaInstagram />
              </a>
            </li>
            <li className="footer__social">
              <a href="https://wa.me/+996779496497">
                <FaWhatsapp />
              </a>
            </li>
            <li className="footer__social">
              <a href="https://m.facebook.com/keis.bishkek?locale=te_IN">
                <FaFacebook />
              </a>
            </li>
            <li className="footer__social">
              <a href="/#">
                <FaYoutube />
              </a>
            </li>
          </ul>
        </div>

        <p className="footer__text">
          © КОЛЛЕДЖ ЭКОНОМИКИ И СЕРВИСА НИУ Кыргызский экономический университет
          имени М.Рыскулбекова
        </p>
      </div>
    </footer>
  );
};

export default Footer;
